import { useChat } from '@/hooks/chat/useChat'
import { Button, IconButton, Paper, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { ChatMessage } from './ChatMessage'
import { IMatchTimeStatus, MeetingType } from '@namuho/types'
import { AccessTime, ThumbDown, ThumbUp } from '@mui/icons-material'
import { ConsumableMatch, useUserMatches } from '@/hooks/user/useUserMatches'
import { useAuth } from '@/contexts'
import { useState } from 'react'
import { formatDate } from '@namuho/helpers'

const useStyles = makeStyles()((theme) => ({
    root: {
        height: '100%',
        minHeight: '70vh',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    chat: {
        height: '100%',
        backgroundColor: theme.palette.romantic?.main,
        border: `1px solid ${theme.palette.primary.main}`,
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
    },
    timeObject: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    closedReason: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: theme.spacing(2),
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        border: `2px solid ${theme.palette.primary.main}`,
        zIndex: theme.zIndex.appBar,
    },
    ratingArea: {
        marginTop: theme.spacing(2),
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        gap: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    textArea: {
        padding: theme.spacing(0.5),
        width: '100%',
        border: `1px solid ${theme.palette.secondary.main}`,
    },
    textIconBox: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
    },
}))

export interface ChatMessagesProps {
    match: ConsumableMatch
}

export const ChatMessages = (props: ChatMessagesProps) => {
    const { messages, isLoading } = useChat(props.match?.id)
    const [rating, setRating] = useState<number | undefined>(undefined)
    const [comment, setComment] = useState<string>('')
    const { timeObject } = props.match || {}
    const { user } = useAuth()
    const { classes } = useStyles()
    const { ratings, comments } = props.match || {}
    const thisUserRating = ratings?.[user?.id || ''] || undefined
    const thisUserComment = comments?.[user?.id || ''] || undefined
    const { rateMatch, commentMatch } = useUserMatches()

    const handleSubmit = async () => {
        if (rating !== undefined) {
            await rateMatch({ matchId: props.match?.id, rating: rating })
        }
        if (comment) await commentMatch({ matchId: props.match?.id, comment: comment })
    }
    const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value)
    }
    const handleUpvote = () => {
        setRating(1)
    }
    const handleDownvote = () => {
        setRating(0)
    }

    return (
        <div className={classes.root}>
            {timeObject && timeObject.status === IMatchTimeStatus.Current && (
                <>
                    <Typography variant="h2">Sprecht darüber, wo ihr euch treffen wollt und wie ihr euch gegenseitig erkennen könnt.</Typography>
                    <div className={classes.timeObject}>
                        <AccessTime />
                        <Typography variant="caption">
                            Chat geöffnet {timeObject.dateChatOpens !== null ? `von ${formatDate(timeObject?.dateChatOpens)}` : ''}
                            {timeObject.dateChatCloses ? ` bis ${formatDate(timeObject?.dateChatCloses)}` : ''}
                        </Typography>
                    </div>
                </>
            )}
            <div className={classes.chat}>
                {timeObject?.status === IMatchTimeStatus.Future && (
                    <Paper className={classes.closedReason}>
                        <Typography variant="h1">
                            Dieser Chat wird am {formatDate(timeObject?.dateChatOpens)} eröffnet, damit ihr euch auf einen Treffpunkt einigen könnt.
                        </Typography>
                    </Paper>
                )}
                {timeObject?.status === IMatchTimeStatus.PastWaiting && !thisUserRating && (
                    <Paper className={classes.closedReason}>
                        <Typography variant="h2">
                            Wie hat dir dein {props.match?.meetingType === MeetingType.Romantic ? 'Date' : 'Treffen'} mit {props.match?.name}{' '}
                            gefallen?
                        </Typography>
                        <div className={classes.ratingArea}>
                            <IconButton
                                color={rating === 0 ? 'primary' : 'default'}
                                aria-label="Etwas ist schiefgelaufen"
                                size="large"
                                onClick={handleDownvote}
                            >
                                <ThumbDown />
                            </IconButton>
                            <IconButton color={rating === 1 ? 'primary' : 'default'} aria-label="Alles war gut" size="large" onClick={handleUpvote}>
                                <ThumbUp />
                            </IconButton>
                            {rating === 0 && (
                                <textarea
                                    className={classes.textArea}
                                    value={comment}
                                    placeholder="Möchtest du uns mitteilen, was dir nicht gefallen hat?"
                                    onChange={handleCommentChange}
                                ></textarea>
                            )}
                            {rating === 1 && (
                                <Typography variant="body1">
                                    Wenn {props.match?.name} euer {props.match?.meetingType === MeetingType.Romantic ? 'Date' : 'Treffen'} gefallen
                                    hat, kannst du über dein Dashboard, bei „Dates und Treffen“ mit {props.match?.name} weiter chatten
                                </Typography>
                            )}
                            <Button disabled={!rating} onClick={handleSubmit} variant="contained" color="primary">
                                Sende deine Bewertung
                            </Button>
                        </div>
                    </Paper>
                )}
                {timeObject?.status === IMatchTimeStatus.PastWaiting && thisUserRating && (
                    <Paper className={classes.closedReason}>
                        <Typography variant="h1">Dieser Chat ist geschlossen.</Typography>
                        <Typography variant="body1">
                            Der Chat wird wieder verfügbar sein, wenn die andere Person das Treffen ebenfalls positiv bewertet.
                        </Typography>
                        {thisUserRating && thisUserRating === 1 && (
                            <div className={classes.textIconBox}>
                                <ThumbUp />
                                <Typography variant="body1">Du hast dieses Treffen positiv bewerten</Typography>
                            </div>
                        )}
                    </Paper>
                )}
                {timeObject?.status === IMatchTimeStatus.PastClosed && (
                    <Paper className={classes.closedReason}>
                        <Typography variant="h1">Dieser Chat ist geschlossen.</Typography>
                        {thisUserRating && thisUserRating === 1 && (
                            <div className={classes.textIconBox}>
                                <ThumbUp />
                                <Typography variant="body1">Du hast dieses Treffen positiv bewerten</Typography>
                            </div>
                        )}
                        {thisUserRating && thisUserRating === 0 && (
                            <div className={classes.textIconBox}>
                                <ThumbDown />
                                <Typography variant="body1">Du hast dieses Treffen negative bewerten</Typography>
                            </div>
                        )}
                        {thisUserComment && (
                            <div className={classes.textIconBox}>
                                <Typography variant="body1">
                                    Dein Kommentar: <strong>{thisUserComment}</strong>
                                </Typography>
                            </div>
                        )}
                    </Paper>
                )}
                {messages?.map((message: any) => (
                    <ChatMessage message={message} match={props.match} key={message?.id} />
                ))}
            </div>
        </div>
    )
}
