import { ChangeEvent } from 'react'
import { Button, Checkbox, Chip, FormControl, FormControlLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { ISlot } from '@namuho/types'
import { format, isBefore, parseISO } from 'date-fns'
import { useSlots } from '@/hooks/slots/useSlots'
import { DottedDivider } from '@/components/common/DottedDivider'
import { useNavigate } from 'react-router-dom'
import useWindowSize from '@/helpers/useWindowSize'
import { Input } from './Input'

export interface TimeSlotsSelectionProps {
    onTimeSlotAdd: (timeSlot: ISlot) => void
    onTimeSlotRemove: (timeSlot: ISlot) => void
    timeSlots: ISlot[]
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            backgroundColor: 'white',
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
}

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
        gap: theme.spacing(2),
    },
    buttons: {
        paddingLeft: theme.spacing(1),
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: theme.spacing(1),
        },
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: theme.spacing(2),
        margin: 0,
        padding: 0,
        [theme.breakpoints.down('md')]: {
            gap: theme.spacing(1),
            flexDirection: 'column',
        },
    },
    legend: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 'max-content',
        padding: theme.spacing(1),
    },
    options: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    label: {
        [theme.breakpoints.down('md')]: {
            fontSize: '1.1rem',
        },
    },
    menuItem: {
        backgroundColor: theme.palette.common.white,
    },
    mobileDivider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
}))
export const TimeSlotsSelection = (props: TimeSlotsSelectionProps) => {
    const { onTimeSlotAdd, onTimeSlotRemove, timeSlots } = props
    const { slots, isLoading: isLoadingSlots } = useSlots()
    const { classes } = useStyles()
    const navigate = useNavigate()
    const { isMobile } = useWindowSize()

    const handleSelectAll = () => {
        slots.forEach((slot: ISlot) => {
            onTimeSlotAdd(slot)
        })
    }

    const handleReset = () => {
        const bookedTimeSlots = timeSlots.filter((slot: ISlot) => slot.match !== undefined).map((slot) => slot.id)
        const slotsThatAreNotBooked = slots.filter((slot: ISlot) => !bookedTimeSlots.includes(slot.id))
        slotsThatAreNotBooked
            .filter((slot) => slot.match === undefined)
            .forEach((slot: ISlot) => {
                onTimeSlotRemove(slot)
            })
    }

    const handleCheck = (e: ChangeEvent<HTMLInputElement>, slotId: string) => {
        const slot = slots.find((slot: ISlot) => slot.id === slotId)
        if (e.target.checked && slot) {
            onTimeSlotAdd(slot)
        } else if (!e.target.checked && slot) {
            onTimeSlotRemove(slot)
        }
    }

    const handleCheckMobile = (e: SelectChangeEvent<string[]>, firstSlotId: string) => {
        const ids = e.target.value
        const selectedSlots = slots.filter((slot) => ids.includes(slot.id))
        const referenceSlot = slots.find((slot) => slot.id === firstSlotId)
        if (!referenceSlot) return
        const selectedSlotsOfThisDay = timeSlots.filter(
            (slot) => format(new Date(slot.date), 'PPPP') === format(new Date(referenceSlot.date), 'PPPP')
        )
        const slotsToRemove = selectedSlotsOfThisDay.filter((slot) => !ids.includes(slot.id))

        selectedSlots.forEach((slot) => {
            onTimeSlotAdd(slot)
        })
        slotsToRemove.forEach((slot) => {
            onTimeSlotRemove(slot)
        })
    }

    const daysStrings: string[] = slots.map((slot: ISlot) => format(new Date(slot.date), 'eeee P')) || []

    const daysStringsDeduplicated = [...new Set(daysStrings)]
    interface DateDisplayObject {
        date: string
        fullDateString: string
        hours: {
            label: string
            slotId: string
        }[]
    }
    const dateDisplayObjects: DateDisplayObject[] = daysStringsDeduplicated
        .map((dateString) => {
            return {
                date: dateString,
                fullDateString: slots.find((slot: ISlot) => format(new Date(slot.date), 'eeee P') === dateString)?.date || '',
                hours: slots
                    .filter((slot: ISlot) => format(new Date(slot.date), 'eeee P') === dateString)
                    .sort((a: ISlot, b: ISlot) => a.date.localeCompare(b.date))
                    .map((slot: ISlot) => ({ label: format(new Date(slot.date), 'H') + ' Uhr', slotId: slot.id })),
            }
        })
        .sort((a, b) => {
            const aDate = parseISO(a.fullDateString)
            const bDate = parseISO(b.fullDateString)
            return isBefore(aDate, bDate) ? -1 : 1
        })
    const getRenderValue = (slotIds: string[]) => {
        if (slotIds.length === 0) {
            return 'hier wählen'
        }
        const selectedSlots = slots.filter((slot) => slotIds.includes(slot.id))
        const sortedSlots = selectedSlots.sort((a: ISlot, b: ISlot) => a.date.localeCompare(b.date))
        return sortedSlots.map((selected) => format(new Date(selected.date), 'H') + ' Uhr').join(', ')
    }

    return (
        <div className={classes.root}>
            <div className={classes.buttons}>
                <Button size="small" onClick={handleSelectAll}>
                    Alle auswählen
                </Button>
                <Button size="small" onClick={handleReset}>
                    Zurücksetzen
                </Button>
            </div>

            {isMobile &&
                dateDisplayObjects.map((day) => (
                    <FormControl key={`${day.date}`}>
                        <label id={day.date}>{day.date}</label>
                        <Select
                            id={day.date}
                            multiple
                            value={timeSlots.filter((slot) => format(new Date(slot.date), 'eeee P') === day.date).map((slot) => slot.id)}
                            onChange={(e) => handleCheckMobile(e, day.hours[0].slotId)}
                            MenuProps={MenuProps}
                            displayEmpty
                            renderValue={getRenderValue}
                        >
                            {day.hours.map((hour) => (
                                <MenuItem classes={{ root: classes.menuItem }} key={hour.slotId} value={hour.slotId}>
                                    <Checkbox checked={timeSlots.map((slot) => slot.id).includes(hour.slotId)} />
                                    <ListItemText primary={hour.label} />
                                </MenuItem>
                            ))}
                        </Select>
                        <DottedDivider className={classes.mobileDivider} />
                    </FormControl>
                ))}
            {!isMobile &&
                dateDisplayObjects.map((day) => (
                    <div key={`${day.date}`}>
                        <fieldset className={classes.row}>
                            <legend className={classes.legend}>{day.date}</legend>
                            <div className={classes.options}>
                                {day.hours.map((hour) => (
                                    <div key={`${hour.slotId}`}>
                                        <FormControlLabel
                                            labelPlacement="start"
                                            classes={{ label: classes.label }}
                                            control={
                                                <Checkbox
                                                    checked={timeSlots.map((slot) => slot.id).includes(hour.slotId)}
                                                    disabled={timeSlots.find((slot) => slot.id === hour.slotId)?.match !== undefined}
                                                    onChange={(e) => {
                                                        handleCheck(e, hour.slotId)
                                                    }}
                                                />
                                            }
                                            label={hour.label}
                                        />
                                        {timeSlots.find((slot) => slot.id === hour.slotId)?.match !== undefined && (
                                            <Chip
                                                label="Gebucht"
                                                onClick={() => {
                                                    navigate('/dates')
                                                }}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </fieldset>
                        <DottedDivider />
                    </div>
                ))}
        </div>
    )
}
