import { useEffect, useState } from 'react'
import { Box, Button, Grid, Card, CardActions, CardContent, CardHeader, Typography, Paper } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { ProductsSkeleton } from '@/skeletons/ProductsSkeleton'
import { IProductV2, MeetingType } from '@namuho/types'
import { BuyProduct } from '../BuyProduct'
import { useProducts } from '@/hooks/products/useProducts'
import { usePreferences } from '@/hooks/preferences/usePreferences'
import { Trans, useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { StepContent } from '../stepper/StepContent'
import { useUser } from '@/hooks/user/useUser'
import { UseFirstDate } from './UseFirstDate'

const useStyles = makeStyles()((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    cardWrapper: {
        justifyContent: 'space-between',
    },
    card: {
        height: '100%',
        color: theme.palette.common.white,
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden',
        padding: '50px 15px 20px 15px',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.15s ease-in !important',
        boxShadow: 'var(--shadow-elevation-medium)',
        backgroundImage:
            'linear-gradient( 225deg, hsl(349deg 55% 53%) 0%, hsl(349deg 53% 52%) 14%, hsl(349deg 52% 51%) 21%, hsl(349deg 51% 51%) 26%, hsl(349deg 50% 50%) 30%, hsl(349deg 50% 50%) 33%, hsl(349deg 51% 49%) 36%, hsl(349deg 51% 48%) 38%, hsl(349deg 51% 48%) 40%, hsl(349deg 51% 47%) 43%, hsl(349deg 52% 47%) 45%, hsl(349deg 52% 46%) 47%, hsl(349deg 52% 45%) 49%, hsl(349deg 52% 45%) 51%, hsl(349deg 53% 44%) 53%, hsl(349deg 53% 44%) 56%, hsl(349deg 53% 43%) 59%, hsl(349deg 54% 42%) 62%, hsl(349deg 54% 42%) 67%, hsl(349deg 54% 41%) 72%, hsl(349deg 55% 41%) 80%, hsl(349deg 55% 40%) 100% )',
        '&:hover': {
            transform: 'scale(1.04)',
            backgroundImage:
                'linear-gradient( 45deg, hsl(349deg 55% 53%) 0%, hsl(349deg 53% 52%) 14%, hsl(349deg 52% 51%) 21%, hsl(349deg 51% 51%) 26%, hsl(349deg 50% 50%) 30%, hsl(349deg 50% 50%) 33%, hsl(349deg 51% 49%) 36%, hsl(349deg 51% 48%) 38%, hsl(349deg 51% 48%) 40%, hsl(349deg 51% 47%) 43%, hsl(349deg 52% 47%) 45%, hsl(349deg 52% 46%) 47%, hsl(349deg 52% 45%) 49%, hsl(349deg 52% 45%) 51%, hsl(349deg 53% 44%) 53%, hsl(349deg 53% 44%) 56%, hsl(349deg 53% 43%) 59%, hsl(349deg 54% 42%) 62%, hsl(349deg 54% 42%) 67%, hsl(349deg 54% 41%) 72%, hsl(349deg 55% 41%) 80%, hsl(349deg 55% 40%) 100% )',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        gap: theme.spacing(4),
        height: '100%',
    },
    contentWrapper: {
        height: '100%',
    },
    desc: {
        paddingLeft: theme.spacing(2),
        paddigRight: theme.spacing(2),
        fontSize: '1.2rem',
        fontWeight: 'normal',
    },
    successMessage: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        textAlign: 'center',
        padding: theme.spacing(2),
        width: '100%',
    },
    type: {
        fontWeight: 'bold',
    },
    dates: {
        fontSize: '4rem',
        fontWeight: 'bold',
    },
    price: {
        marginTop: 'auto',
        paddingLeft: theme.spacing(2),
        paddigRight: theme.spacing(2),
        fontSize: '1.2rem',
        fontWeight: 'bold',
    },
    priceBeforeDiscount: {
        display: 'block',
        color: '#16293A',
        textDecoration: 'line-through',
    },
    button: { marginTop: 'auto' },
    coupon: {
        marginTop: theme.spacing(2),
        width: '100%',
        textAlign: 'center',
        padding: theme.spacing(1),
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontSize: '1.6rem',
        '&:hover': {
            textDecoration: 'underline',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1.2rem',
        },
    },
    firstDate: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2),
        width: 'max-content',
        maxWidth: '100%',
        padding: theme.spacing(2),
        boxShadow: 'var(--shadow-elevation-medium)',
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
    },
    firstDateText: {},
}))
export interface ProductsBuyProps {
    dateType: MeetingType
    onBought: () => void
}
export const ProductsBuy = (props: ProductsBuyProps) => {
    const { dateType } = props
    const { classes } = useStyles()
    const { romanticProducts, friendlyProducts, groupProducts, isLoading } = useProducts()
    const { userData } = useUser()
    const { romanticPreferences, friendlyPreferences, groupPreferences, isLoading: isLoadingPreferences } = usePreferences()
    const [chosenProduct, setChosenProduct] = useState<IProductV2 | null>(null)
    const [buyProductModalOpen, setBuyProductModalOpen] = useState<boolean>(false)
    const [firstDateModalOpen, setFirstDateModalOpen] = useState<boolean>(false)
    const { t } = useTranslation()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams, _setSearchParams] = useSearchParams()

    const products = dateType === MeetingType.Friendly ? friendlyProducts : dateType === MeetingType.Romantic ? romanticProducts : groupProducts

    const [numberOfDates, setNumberOfDates] = useState<number>(0)

    useEffect(() => {
        if (dateType === MeetingType.Friendly) {
            if (friendlyPreferences?.numberOfAvailableSlots) {
                setNumberOfDates(friendlyPreferences.numberOfAvailableSlots || 0)
            }
        }
        if (dateType === MeetingType.Romantic) {
            if (romanticPreferences?.numberOfAvailableSlots) {
                setNumberOfDates(romanticPreferences.numberOfAvailableSlots || 0)
            }
        }
        if (dateType === MeetingType.Group) {
            if (groupPreferences?.numberOfAvailableSlots) {
                setNumberOfDates(groupPreferences.numberOfAvailableSlots || 0)
            }
        }
    }, [friendlyPreferences, romanticPreferences, dateType, groupPreferences])

    const handleCheckout = async (product: IProductV2) => {
        setChosenProduct(product)
        setBuyProductModalOpen(true)
    }

    const handleProductCouponFromBuyModal = () => {
        setBuyProductModalOpen(false)
    }

    const paymentSuccess = searchParams.get('paymentCallback') === 'success'

    return (
        <div className={classes.wrapper}>
            {!isLoading && userData.datesForStart > 0 && numberOfDates === 0 && (
                <Paper className={classes.firstDate}>
                    <Typography variant="h1" className={classes.firstDateText}>
                        Erste Verabredung oder Treffen Gratis
                    </Typography>
                    <Typography variant="body1" className={classes.firstDateText}>
                        Das erste Date oder Treffen bei Namuho ist immer kostenlos.
                    </Typography>
                    <Button onClick={() => setFirstDateModalOpen(true)} variant="contained">
                        Hier einlösen
                    </Button>
                </Paper>
            )}
            <Grid container spacing={2} alignItems="stretch">
                {(isLoadingPreferences || isLoading) && <ProductsSkeleton />}
                {((!(isLoadingPreferences || isLoading) && numberOfDates > 0) || paymentSuccess) && (
                    <StepContent
                        content={
                            <div className={classes.successMessage}>
                                {paymentSuccess && (
                                    <Typography variant="h1">
                                        {chosenProduct && (
                                            <Trans
                                                i18nKey={`congrats.${dateType}`}
                                                count={chosenProduct?.dates || 0 + (chosenProduct?.extraDates || 0)}
                                            >
                                                Herzlichen Glückwunsch!
                                            </Trans>
                                        )}
                                        {!chosenProduct && 'Herzlichen Glückwunsch!'}
                                    </Typography>
                                )}{' '}
                                <Typography variant="body1">
                                    Du hast jetzt{' '}
                                    <strong>
                                        <Trans i18nKey={`meetings.${dateType}`} count={userData[dateType]?.numberOfAvailableSlots}></Trans>
                                    </strong>{' '}
                                    zur Verfügung.
                                </Typography>
                                <Typography variant="caption">
                                    Wenn du Fragen hast, kontaktiere uns unter{' '}
                                    <a className="inline-link" href="mailto:info@namuho.com">
                                        info@namuho.com
                                    </a>
                                </Typography>
                            </div>
                        }
                        buttons={null}
                    />
                )}
                {!isLoadingPreferences &&
                    !paymentSuccess &&
                    numberOfDates === 0 &&
                    products.map((product: IProductV2) => (
                        <Grid className={classes.cardWrapper} item key={product.id} xs={12} md={products.length > 2 ? 4 : 12}>
                            <Card elevation={16} className={classes.card}>
                                <CardHeader
                                    title={product.name}
                                    titleTypographyProps={{
                                        align: 'center',
                                        color: (theme) => theme.palette.primary.contrastText,
                                        fontWeight: 700,
                                        fontSize: '1.5rem',
                                    }}
                                />
                                <CardContent classes={{ root: classes.contentWrapper }}>
                                    <Box className={classes.content}>
                                        <Typography className={classes.dates} component="h2" variant="h4">
                                            {product.dates}
                                        </Typography>
                                        <Typography className={classes.type} variant="h2">
                                            {t(`meetingsWithoutCount.${dateType}`)} {product.extraDates > 0 && `+ ${product.extraDates} Gratis!`}
                                        </Typography>
                                        <Typography className={classes.desc} variant="h2">
                                            {dateType}
                                        </Typography>
                                        {product.bonus && (
                                            <Typography className={classes.desc} variant="h2">
                                                + Teilnahme am Gewinnspiel *
                                            </Typography>
                                        )}
                                        <Typography className={classes.price} variant="h2">
                                            {product.discountedPrice && (
                                                <>
                                                    {' '}
                                                    <span className={classes.priceBeforeDiscount}>
                                                        {((product.price || 0) / 100)?.toLocaleString('de-DE', {
                                                            style: 'currency',
                                                            currency: 'EUR',
                                                        })}
                                                    </span>
                                                    {((product.discountedPrice || 0) / 100)?.toLocaleString('de-DE', {
                                                        style: 'currency',
                                                        currency: 'EUR',
                                                    })}
                                                </>
                                            )}
                                            {!product.discountedPrice &&
                                                ((product.price || 0) / 100)?.toLocaleString('de-DE', {
                                                    style: 'currency',
                                                    currency: 'EUR',
                                                })}
                                        </Typography>
                                    </Box>
                                </CardContent>
                                <CardActions className={classes.button}>
                                    <Button onClick={() => handleCheckout(product)} variant="outlined" fullWidth>
                                        Dates kaufen
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
            </Grid>
            <BuyProduct
                dateType={dateType}
                product={chosenProduct}
                open={buyProductModalOpen}
                handleCancel={() => setBuyProductModalOpen(false)}
                handleBought={() => setBuyProductModalOpen(false)}
                handleProductCoupon={handleProductCouponFromBuyModal}
            />
            <UseFirstDate
                meetingType={dateType}
                open={firstDateModalOpen}
                handleClaimed={() => setFirstDateModalOpen(false)}
                handleCancel={() => setFirstDateModalOpen(false)}
            />
        </div>
    )
}
