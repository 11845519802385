import { useAuth } from '@/contexts'
import { IMatchSecrets, ISecretsQuestionAnswer } from '@namuho/types'
import { useQuery } from '@tanstack/react-query'

export interface SecretsUpdateObject {
    matchId: string
    allSecretsBought?: boolean
    singleSecretsBought?: string[]
}

export const useMatchSecrets = (matchId: string | undefined) => {
    const baseUrl = `https://api.namuho.com/matches/${matchId}/secrets`

    const { getAccessToken, user } = useAuth()
    const matchSecrets = useQuery<IMatchSecrets>([`secrets-${matchId}`], async () => {
        if (!matchId) return Promise.resolve([])
        const response = await fetch(baseUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await getAccessToken()) || '',
            },
        })

        if (!response.ok) {
            throw new Error(response.statusText)
        }

        return response.json()
    })

    const secretsData = matchSecrets?.data
    const myUserId = user?.id

    let secretsAvailable = false
    let secretsAnswers: ISecretsQuestionAnswer[] = []
    let allSecretsBought = false
    let singleSecretsBought: string[] = []
    if (secretsData) {
        secretsAvailable = secretsData.secrets.find((secrets) => secrets.userId === myUserId)?.allSecretsAnswered || false
        secretsAnswers = secretsData.secrets.find((secrets) => secrets.userId === myUserId)?.secretsAnswers || []
        allSecretsBought = secretsData.secrets.find((secrets) => secrets.userId === myUserId)?.allSecretsBought || false
        singleSecretsBought = secretsData.secrets.find((secrets) => secrets.userId === myUserId)?.singleSecretsBought || []
    }

    return {
        isLoading: matchSecrets.isLoading,
        secretsAvailable,
        secretsAnswers,
        allSecretsBought,
        singleSecretsBought,
    }
}
