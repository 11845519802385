import { useEffect, useState } from 'react'
import { Button, Divider, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from '@/store'
import { makeStyles } from 'tss-react/mui'
import { StepContent } from './StepContent'
import { TextInput } from '../common/TextInput'
import { getBackToSecondStep, markStepCompleted, markStepInaccessible, markStepsAccessible, nextStep, resetError, setError } from '@/slices/creator'
import { CreatorButtons } from './CreatorButtons'
import clsx from 'clsx'
import { useAuth } from '@/contexts'
import { setSilentSignIn } from '@/slices/user'
import { Visibility } from '@mui/icons-material'

const useStyles = makeStyles()((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        width: '100%',
    },
    text: {
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        wordBreak: 'break-all',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.1rem',
        },
    },
    textSmall: {
        overflowWrap: 'break-word',
        fontSize: '1.1rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.9rem',
        },
    },
    bottomInfo: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    loading: {
        opacity: '50%',
    },
    passwordBox: {
        position: 'relative',
    },
    passwordIcon: {
        color: theme.palette.common.white,
        position: 'absolute',
        bottom: 10,
        right: theme.spacing(2),
        cursor: 'pointer',
    },
    button: {
        marginTop: theme.spacing(2),
        alignSelf: 'flex-end',
    },
    link: {
        color: theme.palette.primary.main,
    },
}))

export interface ConfirmationCode {
    code: string
    password: string
}

export const ConfirmEmailForm = () => {
    const { register, handleSubmit, watch } = useForm<ConfirmationCode>()
    const user = useSelector((state: RootState) => state.createdUser)
    const { signIn, signOut, confirmEmail, resendCode } = useAuth()
    const watchPassword = watch('password')
    const dispatch = useDispatch()
    const { classes } = useStyles()
    const [loading, setIsLoading] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false)

    useEffect(() => {
        dispatch(setSilentSignIn('NO'))
    }, [dispatch])

    const onSubmit = async (data: ConfirmationCode) => {
        if (user.email && !user.confirmed) {
            try {
                confirmEmail(user.email, data.code.trim())
            } catch (error) {
                console.log('error confirming sign up', error)
            }
        } else if (user.email) {
            signIn(user.email, watchPassword)
        }
    }

    useEffect(() => {
        if (user.silentSignIn === 'PENDING') {
            setIsLoading(true)
        }
        if (user.silentSignIn === 'CONFIRMED' && user.email) {
            signIn(user.email.toLowerCase(), watchPassword)
        }
        if (user.silentSignIn === 'YES') {
            setIsLoading(false)
            dispatch(markStepsAccessible([3, 4, 5, 6, 7, 8, 9, 10, 11]))
            dispatch(resetError())
            dispatch(markStepCompleted(2))
            dispatch(markStepInaccessible(0))
            dispatch(markStepInaccessible(1))
            dispatch(markStepInaccessible(2))
            dispatch(nextStep())
        }
        if (user.silentSignIn === 'WRONG_PASSWORD') {
            setIsLoading(false)
            dispatch(
                setError({
                    error: true,
                    message: 'Passwort ist falsch, versuchen Sie noch mal',
                })
            )
        }
    }, [dispatch, signIn, user.email, user.silentSignIn, watchPassword])

    const handleReset = () => {
        signOut(false)
        dispatch(resetError())
        dispatch(getBackToSecondStep())
    }

    const handleResend = () => {
        if (user.email) {
            resendCode(user.email)
        } else {
            console.error('Keine email address vorhanden')
        }
    }

    const handleNext = () => {
        return null
    }

    return (
        <StepContent
            content={
                <>
                    <Typography className={classes.text} variant="body1">
                        Wir haben dir eine E-Mail an {user.email} gesendet.
                    </Typography>
                    <Typography className={classes.text} variant="body1">
                        Bitte füge den Code ein, den du dort findest.
                    </Typography>
                    <Typography className={classes.textSmall} variant="body1">
                        Manchmal kann es ein paar Minuten dauern, bis der Code eintrifft. Bitte haben Sie einen Moment Geduld.
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)} className={clsx(classes.root, loading && classes.loading)}>
                        <TextInput label="Code" {...register('code')} autoComplete="code" />
                        <div className={classes.passwordBox}>
                            <TextInput
                                label="Passwort"
                                type={passwordVisible ? 'text' : 'password'}
                                {...register('password', { required: true })}
                                autoComplete="password"
                            />
                            <Visibility className={classes.passwordIcon} onClick={() => setPasswordVisible(!passwordVisible)} />
                        </div>
                        <Button variant="contained" className={classes.button} type="submit">
                            Bestätigen
                        </Button>
                    </form>
                    <div className={classes.bottomInfo}>
                        <Typography variant="body1">
                            Falsche E-Mail Adresse?{' '}
                            <Typography className={classes.link} variant="link" onClick={handleReset}>
                                Erneut versuchen
                            </Typography>
                        </Typography>
                        <Typography variant="body1">
                            Es sind ein paar Minuten vergangen und du hast den Code nicht erhalten oder der Code ist abgelaufen?{' '}
                            <Typography className={classes.link} variant="link" onClick={handleResend}>
                                Einen neuen Code senden
                            </Typography>
                        </Typography>
                    </div>
                </>
            }
            buttons={
                <CreatorButtons
                    nextStepActive={false}
                    previousStepActive={false}
                    isFirst={false}
                    isLast={false}
                    skipActive={false}
                    handleNext={handleNext}
                />
            }
        />
    )
}
