import { List, ListItemButton } from '@mui/material'
import { Link } from 'react-router-dom'
import { Link as ScrollLink } from 'react-scroll'
import { makeStyles } from 'tss-react/mui'
import { NavSection } from './NavSection'

type ClassKeys = void | 'list'

const useStyles = makeStyles<ClassKeys>()(() => ({
    root: {},
}))

export function YourData() {
    const { classes } = useStyles()
    return (
        <NavSection title="Deine Daten">
            <List className={classes.root}>
                <ListItemButton component={Link} to="dates">
                    Dates und Treffen
                </ListItemButton>
                <ListItemButton>
                    <ScrollLink
                        className="button-link"
                        to="secrets"
                        spy={true}
                        smooth={true}
                        hashSpy={true}
                        duration={300}
                        isDynamic={true}
                        ignoreCancelEvents={false}
                    >
                        Deine Geheimnisse
                    </ScrollLink>
                </ListItemButton>
                <ListItemButton>
                    <ScrollLink
                        className="button-link"
                        to="timeSlots"
                        spy={true}
                        smooth={true}
                        hashSpy={true}
                        duration={300}
                        isDynamic={true}
                        ignoreCancelEvents={false}
                    >
                        Deine Verfügbarkeiten
                    </ScrollLink>
                </ListItemButton>
                <ListItemButton>
                    <ScrollLink
                        className="button-link"
                        to="personalData"
                        spy={true}
                        smooth={true}
                        hashSpy={true}
                        duration={300}
                        isDynamic={true}
                        ignoreCancelEvents={false}
                    >
                        Persönliche Daten
                    </ScrollLink>
                </ListItemButton>
                <ListItemButton>
                    <ScrollLink
                        className="button-link"
                        to="creditHistory"
                        spy={true}
                        smooth={true}
                        hashSpy={true}
                        duration={300}
                        isDynamic={true}
                        ignoreCancelEvents={false}
                    >
                        Credit Historie
                    </ScrollLink>
                </ListItemButton>
                <ListItemButton>
                    <ScrollLink
                        className="button-link"
                        to="overview"
                        spy={true}
                        smooth={true}
                        hashSpy={true}
                        duration={300}
                        isDynamic={true}
                        ignoreCancelEvents={false}
                    >
                        Übersicht
                    </ScrollLink>
                </ListItemButton>
            </List>
        </NavSection>
    )
}
