import { useState, useEffect } from 'react'
import clsx from 'clsx'
import { Accordion, AccordionDetails, Button, AccordionSummary, Container, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { TimeSlotsSelection } from './common/TimeSlotsSelection'
import { makeStyles } from 'tss-react/mui'
import { useUser } from '@/hooks/user/useUser'
import { useSlots } from '@/hooks/slots/useSlots'
import { usePreferences } from '@/hooks/preferences/usePreferences'
import { ISlot } from '@namuho/types'
import { toast } from 'react-toastify'

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    button: {
        alignSelf: 'flex-end',
    },
    loading: {
        opacity: '50%',
    },
}))

export const TimeSlots = () => {
    const { classes } = useStyles()
    const { userData, isLoading } = useUser()
    const { isLoading: isLoadingSlots } = useSlots()
    const [selectedDates, setSelectedDates] = useState<ISlot[]>([])
    const { updateTimeSlots, updateTimeSlotsLoading } = usePreferences()

    const handleConfirm = async () => {
        await updateTimeSlots(selectedDates)
        toast.success('Deine Verfügbarkeiten erfolgreich gespeichert')
    }

    const handleTimeSlotAdd = (slot: ISlot) => {
        setSelectedDates((prev) => {
            const alreadySelectedIds = prev.map((slot) => slot.id)
            const newSlotId = slot.id
            if (alreadySelectedIds.includes(newSlotId)) {
                return prev
            } else {
                return [...prev, slot]
            }
        })
    }

    const handleTimeSlotRemove = (slot: ISlot) => {
        setSelectedDates((prev) => prev.filter((date) => date.id !== slot.id))
    }

    useEffect(() => {
        const availableDates = userData?.timeSlots
        setSelectedDates(availableDates ?? [])
    }, [userData?.timeSlots])

    const isLoadingAny = isLoading || isLoadingSlots || updateTimeSlotsLoading

    return (
        <Container id="timeSlots">
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="timeSlotsContent" id="timeSlots-header">
                    <Typography variant="h1">Deine Verfügbarkeiten</Typography>
                </AccordionSummary>
                <AccordionDetails id="timeSlotsContent">
                    <div className={clsx(classes.root, isLoadingAny && classes.loading)}>
                        <TimeSlotsSelection onTimeSlotAdd={handleTimeSlotAdd} onTimeSlotRemove={handleTimeSlotRemove} timeSlots={selectedDates} />
                        <Button disabled={isLoadingAny} className={classes.button} variant="contained" color="primary" onClick={handleConfirm}>
                            Speichern
                        </Button>
                    </div>
                </AccordionDetails>
            </Accordion>
        </Container>
    )
}
