import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setUserData, NewUser } from '@/slices/user'
import { TextInput } from '@/components/common/TextInput'
import { StepContent } from '@/components/stepper/StepContent'
import { BirthdayInput } from '@/components/common/BirthDateInput'
import { SelectInput } from '@/components/common/SelectInput'
import { RadioInput } from '@/components/common/RadioInput'
import { markStepCompleted, markStepInaccessible, nextStep, setError } from '@/slices/creator'
import { RootState } from '@/store'
import { Typography } from '@mui/material'
import { CreatorButtons } from './CreatorButtons'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useAuth, UserCreationObject } from '@/contexts'
import { Visibility } from '@mui/icons-material'
import { Country, Gender } from '@namuho/types'
import { StepTitle } from './StepTitle'

const useStyles = makeStyles()((theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    loading: {
        opacity: '50%',
    },
    privacy: {
        marginTop: theme.spacing(2),
        display: 'grid',
        gridTemplateColumns: '40px auto',
        alignItems: 'center',
        gap: theme.spacing(1),
        textAlign: 'justify',
    },
    privacyText: {
        maxWidth: '100%',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            hyphens: 'auto',
            fontSize: '1rem',
        },
    },
    passwordBox: {
        position: 'relative',
    },
    passwordIcon: {
        color: theme.palette.common.white,
        position: 'absolute',
        top: 10,
        right: theme.spacing(2),
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
            top: 65,
        },
    },
}))

export const PersonalDataForm = () => {
    const { register, trigger, formState, watch, setValue, getValues } = useForm<NewUser>()
    const { signUp } = useAuth()
    const user = useSelector((state: RootState) => state.createdUser)
    const creatorError = useSelector((state: RootState) => state.creator.error)
    const { classes } = useStyles()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        const stateUser = user as NewUser
        const formUser = getValues()
        if (shallowEqual(stateUser, formUser)) return
        Object.keys(stateUser).forEach((key) => {
            setValue(key as keyof NewUser, stateUser[key as keyof NewUser])
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const subscription = watch(async (value) => {
            dispatch(setError({ error: false, message: '' }))
            const newUser = {
                email: value.email?.toLowerCase(),
                firstName: value.firstName,
                lastName: value.lastName,
                password: value.password,
                birthDate: value.birthDate,
                country: value.country,
                gender: value.gender,
            }
            dispatch(setUserData(newUser))
        })
        return () => subscription.unsubscribe()
    }, [dispatch, watch])

    const handleNext = async () => {
        const validation = await trigger()
        // pass error sometimes does not get passed to state form - to avoid rerenders
        const passCorrect = await trigger('password')
        if (!validation) {
            const messages: (string | undefined)[] = Object.values(formState.errors)
                .filter((field) => field?.message?.length && field.message.length > 0)
                .map((field) => field.message)
            const errorsArray = [...messages]
            if (!passCorrect) {
                errorsArray.push('Das Passwort muss mindestens 8 Zeichen lang sein und Sonderzeichen und Zahlen enthalten.')
            }
            dispatch(setError({ error: true, message: [...new Set(errorsArray)].join('; ') || 'Alle Felder sind obligatorisch' }))
            return
        }
        dispatch(setError({ error: false, message: '' }))
        try {
            setLoading(true)
            const userObject: UserCreationObject = {
                firstname: user.firstName || '',
                lastname: user.lastName || '',
                email: user.email?.toLowerCase() || '',
                birthdate: user.birthDate || '',
                password: user.password || '',
                gender: user.gender || '',
                city: user.city || '',
                country: user.country || '',
            }
            signUp(userObject)
            dispatch(markStepInaccessible(0))
            dispatch(markStepInaccessible(1))
            dispatch(markStepCompleted(1))
            dispatch(nextStep())
        } catch (error) {
            dispatch(setError({ error: true, message: JSON.stringify(error) }))
        }
        setLoading(false)
    }

    const nextStepActive = !loading && !creatorError.error

    const validatePassword = (password: string | undefined) => {
        if (!password) return false
        if (password.length < 8) return false
        // checks if password contains special characters
        if (!password.match(/[^A-Za-z0-9]/)) return false
        // checks if password contains numbers
        if (!password.match(/[0-9]/)) return false
        return true
    }

    const genders = useMemo(() => {
        return [
            {
                label: t('genders.Male'),
                value: Gender.Male,
            },
            {
                label: t('genders.Female'),
                value: Gender.Female,
            },
            {
                label: t('genders.Diverse'),
                value: Gender.Other,
            },
        ]
    }, [t])
    return (
        <StepContent
            title={<StepTitle title="Bitte gib uns einige grundlegende Informationen über dich" />}
            content={
                <form className={clsx(classes.root, loading && classes.loading)}>
                    <TextInput label="Vorname" {...register('firstName', { required: 'Alle Felder sind obligatorisch' })} />
                    <TextInput label="Nachname" {...register('lastName', { required: 'Alle Felder sind obligatorisch' })} />
                    <BirthdayInput label="Geburtsdatum" {...register('birthDate', { required: 'Alle Felder sind obligatorisch' })} />
                    <SelectInput
                        label="Land"
                        options={Object.keys(Country).map((country) => ({
                            label: t(`countries.${country}`),
                            value: country,
                        }))}
                        {...register('country', { required: 'Alle Felder sind obligatorisch' })}
                    />
                    <RadioInput
                        label="Geschlecht"
                        options={genders.map((type) => ({
                            label: type.label,
                            value: type.value,
                        }))}
                        {...register('gender', { required: 'Alle Felder sind obligatorisch' })}
                    />
                    <TextInput label="E-Mail Addresse" type="email" {...register('email', { required: 'Alle Felder sind obligatorisch' })} />
                    <div className={classes.passwordBox}>
                        <TextInput
                            label="Passwort"
                            type={passwordVisible ? 'text' : 'password'}
                            helperText="Das Passwort muss mindestens 8 Zeichen lang sein und Sonderzeichen und Zahlen enthalten."
                            {...register('password', {
                                validate: (pass) => {
                                    return (
                                        validatePassword(pass) ||
                                        'Das Passwort muss mindestens 8 Zeichen lang sein und Sonderzeichen und Zahlen enthalten.'
                                    )
                                },
                            })}
                        />
                        <Visibility className={classes.passwordIcon} onClick={() => setPasswordVisible(!passwordVisible)} />
                    </div>
                    <label htmlFor="privacy" className={classes.privacy}>
                        <input
                            type="checkbox"
                            id="privacy"
                            {...register('privacy', {
                                validate: (value) => value || 'Bitte akzeptieren Sie den Datenschutz und die Nutzungsbedingungen',
                            })}
                        />
                        <Typography className={classes.privacyText} variant="caption">
                            Bevor Sie unsere Website nutzen können, müssen Sie unsere{' '}
                            <a className="inline-link" href="https://namuho.com/agb/" title="AGB" target="_blank" rel="noreferrer">
                                Allgemeinen Geschäftsbedingungen
                            </a>{' '}
                            (AGB) und{' '}
                            <a
                                className="inline-link"
                                href="https://namuho.com/datenschutzerklaerung/"
                                title="Datenschutzerklärung"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Datenschutzerklärung
                            </a>{' '}
                            akzeptieren. Die AGBs regeln Ihre Nutzung unserer Dienste und unsere Haftung, während die Datenschutzerklärung beschreibt,
                            wie wir personenbezogene Daten verwenden und schützen. Bitte lesen Sie diese Bedingungen sorgfältig durch, bevor Sie
                            fortfahren.
                        </Typography>
                    </label>
                </form>
            }
            buttons={
                <CreatorButtons
                    nextStepActive={nextStepActive}
                    previousStepActive={true}
                    isFirst={false}
                    isLast={false}
                    skipActive={false}
                    handleNext={handleNext}
                />
            }
        />
    )
}
