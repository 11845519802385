import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from '@/store'
import { makeStyles } from 'tss-react/mui'
import { useForm } from 'react-hook-form'
import { setCity } from '@/slices/user'
import { StepContent } from './StepContent'
import { CreatorButtons } from './CreatorButtons'
import { CreatorSteps, markStepCompletedByName, nextStep, setError } from '@/slices/creator'
import { City as CityEnum } from '@namuho/types'
import { RadioInput } from '../common/RadioInput'
import { usePreferences } from '@/hooks/preferences/usePreferences'
import { useUser } from '@/hooks/user/useUser'

const useStyles = makeStyles()((theme) => ({
    root: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(2),
    },
    wrapper: {
        maxWidth: 400,
    },
}))

export interface CityForm {
    city: CityEnum
}

export const City = () => {
    const { register, getValues, setValue, watch } = useForm<CityForm>()
    const { userData } = useUser()
    const { classes } = useStyles()
    const dispatch = useDispatch()
    const { updateCity } = usePreferences()

    const handleNext = () => {
        const city = getValues('city')
        if (!city) {
            dispatch(setError({ error: true, message: 'Bitte wähle dein Stadt' }))
        }
        updateCity(city)
        dispatch(setCity(city))
        dispatch(markStepCompletedByName(CreatorSteps.CITY))
        dispatch(nextStep())
    }

    useEffect(() => {
        const subscription = watch(async () => {
            dispatch(setError({ error: false, message: '' }))
        })
        return () => subscription.unsubscribe()
    }, [dispatch, watch])

    useEffect(() => {
        if (userData?.city) {
            setValue('city', userData.city)
        }
    }, [setValue, userData?.city])

    return (
        <StepContent
            content={
                <form className={classes.root}>
                    <RadioInput
                        centered
                        label="In welcher Stadt soll dein Date/Treffen stattfinden?"
                        options={Object.values(CityEnum).map((city) => ({
                            label: city,
                            value: city,
                        }))}
                        {...register('city')}
                    />
                </form>
            }
            buttons={
                <CreatorButtons
                    nextStepActive={true}
                    previousStepActive={false}
                    isFirst={true}
                    isLast={false}
                    skipActive={false}
                    handleNext={handleNext}
                />
            }
        />
    )
}
