import { useAuth } from '@/contexts'
import { IProductV2, MeetingType, ProductType } from '@namuho/types'
import { useQuery } from '@tanstack/react-query'

export const useProducts = () => {
    const baseUrl = 'https://api.namuho.com/v2/products'

    const { getAccessToken, session } = useAuth()
    const products = useQuery({
        queryKey: ['products'],
        queryFn: async () => {
            const response = await fetch(`${baseUrl}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: (await getAccessToken()) || '',
                },
            })

            if (!response.ok) {
                throw new Error(response.statusText)
            }

            return response.json()
        },
        enabled: !!session,
    })

    return {
        isLoading: products.isLoading,
        singleSecretProduct: products?.data?.find((product: IProductV2) => product.productType === ProductType.SingleSecret) || undefined,
        allSecretsProduct: products?.data?.find((product: IProductV2) => product.productType === ProductType.AllSecrets) || undefined,
        groupProducts:
            products?.data
                ?.filter((product: IProductV2) => product.meetingType === MeetingType.Group)
                .sort((a: IProductV2, b: IProductV2) => a.ordering - b.ordering) || [],
        romanticProducts:
            products?.data
                ?.filter((product: IProductV2) => product.meetingType === MeetingType.Romantic)
                .sort((a: IProductV2, b: IProductV2) => a.ordering - b.ordering) || [],
        friendlyProducts:
            products?.data
                ?.filter((product: IProductV2) => product.meetingType === MeetingType.Friendly)
                .sort((a: IProductV2, b: IProductV2) => a.ordering - b.ordering) || [],
    }
}
