import { useAuth } from '@/contexts'
import { IQuestion } from '@namuho/types'
import { useQuery } from '@tanstack/react-query'

export const useQuestions = () => {
    const baseUrl = 'https://api.namuho.com/questions'

    const { session, getAccessToken } = useAuth()
    const questions = useQuery({
        queryKey: ['questions'],
        queryFn: async () => {
            const response = await fetch(`${baseUrl}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: (await getAccessToken()) || '',
                },
            })

            if (!response.ok) {
                throw new Error(response.statusText)
            }

            return response.json()
        },
        enabled: !!session,
    })

    return {
        isLoading: questions.isLoading,
        secrets: questions?.data?.filter((question: IQuestion) => question.group === 'Secrets') || [],
        weighted: questions?.data?.filter((question: IQuestion) => question.group === 'Weighted') || [],
    }
}
