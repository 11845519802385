import { useAuth } from '@/contexts'
import { MeetingType } from '@namuho/types'
import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'

export const useCodes = (code: string) => {
    const baseUrl = 'https://api.namuho.com/codes/'

    const { getAccessToken } = useAuth()
    const verifyCode = useQuery({
        queryKey: ['code', code],
        queryFn: async () => {
            const response = await fetch(`${baseUrl}${code}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: (await getAccessToken()) || '',
                },
            })

            if (!response.ok) {
                throw new Error(response.statusText)
            }

            return response.json()
        },
        enabled: false,
    })

    const consume = useMutation({
        mutationKey: ['code', code],
        mutationFn: async (meetingType: MeetingType) => {
            const body = {
                meetingType,
            }
            const response = await fetch(`${baseUrl}${code}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: (await getAccessToken()) || '',
                },
                body: JSON.stringify(body),
            })
            return response.json()
        },
        onSuccess: (data) => {
            if (data.code === 'OK') {
                toast.success('Code erfolgreich eingelöst')
            }
        },
        onError: () => {
            toast.error('Etwas ist schiefgelaufen')
        },
    })

    return {
        codeData: verifyCode.data,
        verifyCode: verifyCode.refetch,
        consume: consume.mutateAsync,
        isLoading: verifyCode.isLoading || consume.isLoading,
    }
}
